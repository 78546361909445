import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SelectInvestmentProfile from "../../../../components/select/selectInvestmentProfile";
import CheckSelect from "../../../../components/select/CheckSelect";
import axios from "../../../../utils/axiosHelper";
import SelectField from "../../../../components/select/selectField";
import RangeSelector from "../../../../components/rangeSelector";
import { datePayload, getSelectedDate } from "../../../../utils/moduleTabApi";
import { GlobalData } from "../../../../context/globalData";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import CardAndTable from "./CardAndTable";
import { metricList } from "../../../../Config/statusData";
import getCheckUrl from "../../../../components/UrlSelected/getCheckUrl";

export const BenchMark = ({ projectId }) => {
  const [TeamOrResource, setTeamOrResource] = useState();
  const [AllTeamList, setAllTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [AssigneeList, setAssigneeList] = useState([]);
  const [SelectedAssigneeList, setSelectedAssigneeList] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState([]);
  const [ResourceUrl, setResourceUrl] = useState();
  const [teamUrl, setTeamUrl] = useState();
  const [MetricUrl, setMetricUrl] = useState();
  const [openRange, setOpenRange] = useState(false);
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [benchmark, setBenchmark] = useState([]);

  // console.log("first", teamAndResourceUrl);

  // Team List api
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/teams/list/kpis?${dateUrl}&iteration=7&project_id=${projectId}`;
    axios
      .get(url)
      .then((response) => {
        const list = response?.data.map((item) => {
          return item?.TeamName;
        });
        setAllTeamList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId, date]);

  // Resource List api
  useEffect(() => {
    const dateUrl = datePayload(date);
    axios
      .get(`/api/v2/projects/resourceAllocation/${projectId}?${dateUrl}`)
      .then((res) => {
        if (res?.data) {
          const data = Object.keys(res?.data).map((e) => {
            return { title: e, value: res?.data[e].toFixed() };
          });
          setAssigneeList(
            data.map((e) => e?.title).filter((e) => e !== "null")
          );
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [projectId, date]);

  // Url For API
  useEffect(() => {
    const teamUrl = getCheckUrl("teamName", selectedTeam);
    setTeamUrl(teamUrl);
  }, [selectedTeam]);
  useEffect(() => {
    const teamUrl = getCheckUrl("resourceName", SelectedAssigneeList);
    setResourceUrl(teamUrl);
  }, [SelectedAssigneeList]);
  useEffect(() => {
    const teamUrl = getCheckUrl("metricName", selectedMetric);
    setMetricUrl(teamUrl);
  }, [selectedMetric]);

  const selectTeamOrResourceTypeHandler = (event) => {
    setTeamOrResource(event?.target?.value);
    setSelectedTeam([]);
    setSelectedAssigneeList([]);
    setSelectedMetric([]);
  };

  // Benchmark API
  useEffect(() => {
    const dateUrl = datePayload(date);
    if (TeamOrResource) {
      // let url = `/api/v2/projects/churnBenchmark?projectId=${projectId}&${dateUrl}`;
      let url = `/api/v2/compare/benchmark?projectId=${projectId}&${dateUrl}`;
      if (TeamOrResource) {
        url += `&viewBy=${TeamOrResource}`;
      }
      if (ResourceUrl) {
        url += ResourceUrl;
      }
      if (teamUrl) {
        url += teamUrl;
      }
      if (MetricUrl) {
        url += MetricUrl;
      }
      axios
        .get(url)
        .then((response) => {
          setBenchmark(response?.data);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, [TeamOrResource, ResourceUrl, teamUrl, MetricUrl, date]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <SelectField
            input={
              <Button
                onClick={() => setOpenRange(true)}
                style={{
                  border: "1px solid #b7b7b7",
                  width: "230px",
                  textTransform: "capitalize",
                  padding: "5px",
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingLeft: "14px ",
                  paddingRight: "11px",
                  justifyContent: "space-between",
                  borderRadius: "5px",
                  height: "54px",
                  color: "black",
                }}
                endIcon={<DateRangeOutlinedIcon />}
              >
                {getSelectedDate(date)}
              </Button>
            }
            onClick={() => setOpenRange(true)}
          />
          <RangeSelector
            open={openRange}
            setOpen={setOpenRange}
            pickerHandler={(val) => setDate(val)}
            value={date}
            isNextDate={false}
          />
        </div>
        <div className="d-flex">
          {/* Main Dropdown */}
          <Box display={"flex"} alignItems={"center"}>
            <div className="mx-2">View Type:</div>
            <SelectInvestmentProfile
              handleChange={selectTeamOrResourceTypeHandler}
              list={["Team", "Contributor"]}
              value={TeamOrResource}
              iteration={true}
            />
          </Box>
          {/* Team Dropdown */}
          {TeamOrResource === "Team" ? (
            <div className="pt-2">
              <CheckSelect
                names={AllTeamList}
                setValue={(value) => {
                  setSelectedTeam(value);
                }}
                value={selectedTeam}
              />
            </div>
          ) : null}
          {/* Contributor Dropdown */}
          {TeamOrResource === "Contributor" ? (
            <div className="pt-2">
              <CheckSelect
                names={AssigneeList}
                setValue={(value) => {
                  setSelectedAssigneeList(value);
                }}
                value={SelectedAssigneeList}
              />
            </div>
          ) : null}
          {/* Metrics Dropdown */}
          {selectedTeam.length >= 2 || SelectedAssigneeList.length >= 2 ? (
            <div className="pt-2 d-flex mx-2">
              <div className="pt-2 mx-1">Metrics: </div>
              <CheckSelect
                names={metricList}
                setValue={(value) => {
                  setSelectedMetric(value);
                }}
                value={selectedMetric}
              />
            </div>
          ) : null}
        </div>
      </div>
      {/* Card and Table Section */}
      {TeamOrResource ? (
        <CardAndTable benchmark={benchmark} />
      ) : (
        <Box
          height={400}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          Please Select Contributor Or Team To Compare
        </Box>
      )}
    </>
  );
};
