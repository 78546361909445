import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { red } from "@material-ui/core/colors";
import userImage from "../../../assets/id_img.jpg";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import instance from "../../../utils/axiosHelper";
import { color } from "../../../Config/statusData";
import { GlobalData } from "../../../context/globalData";
import { Avatar, AvatarGroup, Box, Tooltip } from "@mui/material";
import { config } from "../../../Config/permission";

const useStyles = makeStyles({
  // table: {
  //   minWidth: 650,
  // },
  saveicon: {
    width: 30,
    height: 30,
    backgroundColor: red,
  },
  "MuiTab-root": {
    padding: 0,
  },
  tablecont: {
    marginTop: "40px",
    "& td": {
      // borderRight: "1px solid #80808047",
      padding: "20px",
      textAlign: "center",
    },
    "& td:last-child": {
      borderRight: "0px solid #80808047",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiTableCell-head": {
      boxShadow: "none",
      color: "black",
      fontWeight: "bold",
      background: "#F2F2F2 0% 0% no-repeat padding-box",
      textAlign: "center",
    },
  },
  firstcoll: {
    display: "Flex",
    gap: "10px",
    "& label": {
      "font-size": "14px",
      fontWeight: "600",
    },
  },
  righticon: {
    height: "14px",
    width: "14px",
    background: "green",
    borderRadius: "2px",
    display: "flex",
    marginTop: "4px",
    alignItems: "center",
    "justify-content": "center",
  },
  BookmarkIcon: {
    color: "red",
  },
  //status tab css
  satustabFirst: {
    height: "30px",
    padding: "3px 5px",
    border: "1px solid #00000059",
    borderRadius: "2px",
    fontWeight: "600",
    textAlign: "center",
    "& label": {
      "font-size": "16px",
      color: "gray",
      fontWeight: "500",
    },
  },

  satustabSecond: {
    height: "30px",
    padding: "3px 5px",
    border: "1px solid #80808033",
    borderRadius: "2px",
    fontWeight: "600",
    textAlign: "center",
    backgroundColor: "#a0a4cb26",
    "& label": {
      "font-size": "16px",
      color: "#000000d1",
      fontWeight: "600",
    },
  },

  satustabThirdReleased: {
    height: "30px",
    padding: "3px 5px",
    border: "1px solid #80808033",
    borderRadius: "2px",
    fontWeight: "600",
    textAlign: "center",
    backgroundColor: "#8893ff2e",
    "& label": {
      "font-size": "16px",
      color: "#617cff",
      fontWeight: "600",
    },
  },
  satustabThirdClosed: {
    height: "30px",
    padding: "3px 5px",
    border: "1px solid #80808033",
    borderRadius: "2px",
    fontWeight: "600",
    textAlign: "center",
    backgroundColor: "#8893ff2e",
    "& label": {
      "font-size": "16px",
      color: "red",
      fontWeight: "600",
    },
  },
  satustabThirdDone: {
    height: "30px",
    padding: "3px 5px",
    border: "1px solid #80808033",
    borderRadius: "2px",
    fontWeight: "600",
    textAlign: "center",
    backgroundColor: "#8893ff2e",
    "& label": {
      "font-size": "16px",
      color: "green",
      fontWeight: "600",
    },
  },
  // satustabThirdInprogress: {
  //   height: "30px",
  //   padding: "3px 5px",
  //   border: "1px solid #80808033",
  //   borderRadius: "2px",
  //   fontWeight: "600",
  //   textAlign: "center",
  //   backgroundColor: "#8893ff2e",
  //   "& label": {
  //     "font-size": "16px",
  //     color: "#617cff",
  //     fontWeight: "600",
  //   },
  // },

  statusthree: {
    display: "flex",
    gap: "10px",
    marginLeft: "25px",
    marginTop: "10px",
  },
  allfirstcol: {
    display: "flex",
    justifyContent: "space-between",
  },
  //estimate delivery
  deliveryDone: {
    height: "auto",
    width: "auto",
    padding: "10px 10px",
    backgroundColor: "#0080007a",
    margin: "auto",
    alignItems: "center",
    borderRadius: "2px",
    "& label": {
      color: "#000000c9",
      fontWeight: "600",
    },
    textAlign: "center",
  },
  deliveryReproduce: {
    height: "40px",
    width: "auto",
    padding: "10px 10px",
    backgroundColor: "#ff000070",
    margin: "auto",
    alignItems: "center",
    borderRadius: "2px",
    "& label": {
      color: "#000000c9",
      fontWeight: "600",
    },
    textAlign: "center",
  },
  deliveryWontFixNow: {
    height: "40px",
    width: "auto",
    padding: "10px 10px",
    backgroundColor: "#ffff0066",
    margin: "auto",
    alignItems: "center",
    borderRadius: "2px",
    "& label": {
      color: "#000000c9",
      fontWeight: "600",
    },
    textAlign: "center",
  },

  deliveryNoStatus: {
    height: "40px",
    width: "auto",
    padding: "10px 10px",
    backgroundColor: "#0000ff6b",
    margin: "auto",
    alignItems: "center",
    borderRadius: "2px",
    "& label": {
      color: "#000000c9",
      fontWeight: "600",
    },
    textAlign: "center",
  },

  members: {
    "& img": {
      height: "32px",
      width: "32px",
      borderRadius: "50%",
      marginLeft: "-10px",
    },
    "& img:first-child": {
      marginLeft: "0px",
    },
  },
  gitchanges: {
    width: "12%",
    "& h6": {
      margin: "0px",
      fontSize: "16px",
      fontWeight: "500",
      marginBottom: "10px",
    },
    "& p": {
      fontSize: "14px",
      color: "#000000c9",
      margin: "0px",
    },
  },
  dote: {
    borderRadius: "4px",
    backgroundColor: "blue",
    padding: "4px",
    display: "inline-block",
  },
  gitbranches: {
    width: "12%",
    "& span": {
      fontSize: "16px",
      paddingLeft: "10px",
      fontWeight: "400",
      color: "gray",
    },
  },
  "MuiTableCell-alignLeft": {
    textAlign: "left",
  },
  "MuiTableSortLabel-root": {
    color: "black",
  },
  wd: {
    width: "10%",
    color: "#26a6bb",
    cursor: "pointer",
  },
  whtspc: {
    whiteSpace: "nowrap",
  },
  crd: {
    border: "1px solid lightgray",
    padding: "20px 26px ",
    marginTop: "20px",
    boxShadow: "5px 5px 17px lightgrey",
    borderRadius: "8px",
  },
});

const StatusTab = (props) => {
  const { type, value } = props;
  const classes = useStyles();

  return (
    <div
      className={
        type === "line"
          ? classes.satustabFirst
          : type === "points"
          ? classes.satustabSecond
          : type === "status" && value === "Closed"
          ? classes.satustabThirdClosed
          : type === "status" && value === "Released"
          ? classes.satustabThirdReleased
          : classes.satustabThirdDone
      }
    >
      {type === "points" ? (
        <label>
          {props.value !== null ? `${props.value} Points` : "Not Linked"}
        </label>
      ) : (
        <label>{props.value}</label>
      )}
    </div>
  );
};

const EstimateDelivery = (props) => {
  const classes = useStyles();
  return (
    <div
      className={
        props.value === "Done"
          ? classes.deliveryDone
          : props.value === "Cannot Reproduce"
          ? classes.deliveryReproduce
          : props.value === "Won't fix now"
          ? classes.deliveryWontFixNow
          : classes.deliveryNoStatus
      }
    >
      {/* <ScheduleIcon style={{ color: "red", fontSize: "20px" }} /> */}
      <label>{props.value ? props.value : "No status"}</label>
    </div>
  );
};

const AddedRemoved = (item, index) => {
  if (!item) return;
  let add = 0;
  let remove = 0;
  item.map((data) => {
    data.repository?.map((repo) => {
      add = repo?.commits?.length ?? 0;
      remove = repo?.pullRequests?.length ?? 0;
    });
  });
  return (
    <>
      {add === 0 && remove === 0 ? (
        <>
          <label style={{ fontWeight: "300", fontSize: "15px" }}>
            Commit Not Link
          </label>
        </>
      ) : (
        <>
          <label
            style={{ fontWeight: "600", fontSize: "15px" }}
          >{`Commits ${add}`}</label>
          <br></br>
          <label
            style={{ fontWeight: "600", fontSize: "15px" }}
          >{`Pull Requests ${remove}`}</label>
        </>
      )}
    </>
  );
};

const formatTime = (totalTimeSpentInSeconds) => {
  const hours = Math.floor(totalTimeSpentInSeconds / 3600);
  const remainingHours = hours % 8;
  const days = Math.floor(hours / 8);
  const remainingDays = days % 5;
  const weeks = Math.floor(days / 5);
  const minutes = Math.floor((totalTimeSpentInSeconds % 3600) / 60);

  if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? "s" : ""} ${remainingDays} day${
      remainingDays !== 1 ? "s" : ""
    } ${remainingHours} hour${
      remainingHours !== 1 ? "s" : ""
    } and ${minutes} minute${minutes !== 1 ? "s" : ""}`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ${remainingHours} hour${
      remainingHours !== 1 ? "s" : ""
    } and ${minutes} minute${minutes !== 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""} and ${minutes} minute${
      minutes !== 1 ? "s" : ""
    }`;
  } else {
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  }
};

export const JiraTable = (props) => {
  const {
    metricBarData,
    searchJiraIssue,
    projectId,
    boardId,
    sprintName,
    searchJiraIssueItem,
    tableLength,
  } = props;

  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 500);
  }, [searchJiraIssue]);

  console.log(
    config.sprint.sprintLevelIsWorkCommitIssueLevelEmptyDevAndQa,
    "---->"
  );

  return (
    <>
      <TableContainer
        component={Paper}
        className={classes.tablecont}
        elevation={0}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell>{`Issues (${totalWorkCommit[0]?.WorkCommitment}) `}</TableCell> */}
              <TableCell>{`Issues (${tableLength || 0}) `}</TableCell>
              <TableCell align="left">Assignee</TableCell>
              {config?.sprint
                ?.sprintLevelIsWorkCommitIssueLevelEmptyDevAndQa && (
                <TableCell align="left">Developer</TableCell>
              )}
              {config?.sprint
                ?.sprintLevelIsWorkCommitIssueLevelEmptyDevAndQa && (
                <TableCell align="left">QA</TableCell>
              )}
              <TableCell align="left">Git Changes</TableCell>
              {config.sprint.sprintLevelWorkCommitIssueLevelStoryColumn && (
                <TableCell align="left">Story Points</TableCell>
              )}
              {config.sprint
                .sprintLevelWorkCommitIssueLevelRemainAndAllowTime && (
                <TableCell align="left">Remaining Time</TableCell>
              )}
              {config.sprint
                .sprintLevelWorkCommitIssueLevelRemainAndAllowTime && (
                <TableCell align="left">Allocated Time</TableCell>
              )}
              {config.sprint.sprintLevelWorkCommitIssueLevelTimeSpent && (
                <TableCell align="left">Time Spent</TableCell>
              )}
              <TableCell className={classes.wd}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchJiraIssue?.map((item, index) => (
              <ExapndRow
                item={item}
                index={index}
                projectId={projectId}
                boardId={boardId}
                sprintName={sprintName}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const ExapndRow = (props) => {
  const { item, index, projectId, boardId, sprintName } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isRotate, setIsRotae] = useState(false);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(-1);
  const [steps, setSteps] = useState([]);
  // Avatar img
  const getAvatar = (name) => {
    const words = name.split(" ");
    const firstLetter = words[0].charAt(0);
    const secondLetter = words.length > 1 ? words[1].charAt(0) : "";

    return firstLetter + secondLetter;
  };

  useEffect(() => {
    if (isOpen) {
      instance
        .get(
          `/api/v2/sprints/sprintIssueMovement/${projectId}?key=${item?.key}&boardId=${boardId}`
        )
        .then((res) => {
          setSteps(
            res?.data?.map(
              (e) => (e?.uniqueValues && e.uniqueValues[0]) || sprintName
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isOpen]);
  // let steps = [];
  // item?.fields?.customfield_10007?.map((e) => {
  //   steps.push(e.name);
  // });
  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };
  // function getStepContent(step) {}
  return (
    <>
      <TableRow
        key={0}
        onClick={(e) => {
          setIsOpen(!isOpen);
          setIsRotae(!isRotate);
        }}
      >
        <TableCell
          component="td"
          scope="row"
          style={{ width: "34%" }}
          key={index}
        >
          <div className={classes.allfirstcol}>
            <div style={{ width: "100%" }}>
              <div className={classes.firstcoll}>
                <img src={item?.fields?.issuetype["iconUrl"]} alt="car" />
                <label>{item?.fields?.summary}</label>
              </div>
              <div className={classes.statusthree}>
                <a href={item?.url}>
                  {item?.key}
                  {/* <StatusTab type={"line"} value={item?.key} /> */}
                </a>
                <StatusTab
                  type={"points"}
                  value={item?.fields?.customfield_10257 || ""}
                />
                <StatusTab type={"status"} value={item?.fields?.status?.name} />
              </div>
            </div>

            <div>{/* <InfoIcon style={{ color: "orange" }} /> */}</div>
          </div>
        </TableCell>
        {/* Assignee */}
        <TableCell align="left" style={{ width: "15%" }}>
          <Box display={"flex"} justifyContent={"center"}>
            <Tooltip
              title={item?.fields?.assignee?.displayName}
              placement="top"
              arrow
            >
              <Avatar
                alt={item?.fields?.assignee?.displayName}
                src={item?.fields?.assignee?.avatarUrls?.["24x24"]}
              />
            </Tooltip>
          </Box>
        </TableCell>
        {/* Developer */}
        {config.sprint.sprintLevelIsWorkCommitIssueLevelEmptyDevAndQa && (
          <TableCell align="left" style={{ width: "15%" }}>
            <>
              {Array.isArray(item?.fields[config.sprint.sprintLevelIsWorkCommitIssueLevelDev]) &&
                item.fields[config.sprint.sprintLevelIsWorkCommitIssueLevelDev].map((e, index) => (
                  <b key={index}>&#x2022; {e?.displayName}</b>
                ))}
            </>
          </TableCell>
        )}
        {/* QA */}
        {config.sprint.sprintLevelIsWorkCommitIssueLevelEmptyDevAndQa && (
          <TableCell align="left" style={{ width: "15%" }}>
            <>
              {Array.isArray(item?.fields[config.sprint.sprintLevelIsWorkCommitIssueLevelQa]) &&
                item?.fields[config.sprint.sprintLevelIsWorkCommitIssueLevelQa].map((e, index) => (
                  <b key={index}>&#x2022; {e?.displayName}</b>
                ))}
            </>
          </TableCell>
        )}
        <TableCell align="left" className={classes.gitchanges}>
          {AddedRemoved(
            item?.commit?.developmentInformation?.details?.instanceTypes
          )}
        </TableCell>
        {config.sprint.sprintLevelWorkCommitIssueLevelStoryColumn && (
          <TableCell
            align="left"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px",
            }}
          >
            <>
              <div style={{ width: "60%" }}>
                {/* <EstimateDelivery value={item?.fields?.resolution?.name} /> */}
                {item?.fields[config.sprint.sprintLevelIsWorkCommitIssueLevelStroyPoint]
                  ? item?.fields[config.sprint.sprintLevelIsWorkCommitIssueLevelStroyPoint]
                  : "-"}
              </div>
            </>
          </TableCell>
        )}
        {config.sprint.sprintLevelWorkCommitIssueLevelRemainAndAllowTime && (
          <TableCell>
            {item?.fields?.timeestimate
              ? formatTime(item?.fields?.timeestimate)
              : "-"}
          </TableCell>
        )}
        {config.sprint.sprintLevelWorkCommitIssueLevelRemainAndAllowTime && (
          <TableCell>
            {item?.fields?.aggregatetimeoriginalestimate
              ? formatTime(item?.fields?.aggregatetimeoriginalestimate)
              : "-"}
          </TableCell>
        )}
        {config.sprint.sprintLevelWorkCommitIssueLevelTimeSpent && (
          <TableCell>
            {item?.fields?.timespent
              ? formatTime(item?.fields?.timespent)
              : "-"}
          </TableCell>
        )}
        <TableCell className={classes.wd}>
          <ChevronRightIcon
            style={{ transform: `rotate(${isRotate ? "90deg" : "0Deg"})` }}
          />{" "}
          {!isRotate ? "View More" : "View Less"}
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <TableCell align="left" colSpan={5}>
            <div className={classes.root}>
              <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const buttonProps = {};
                  return (
                    <Tooltip title="Sprint Flow" arrow placement="top">
                      <Step key={label} {...stepProps}>
                        {/* onClick={handleStep(index)} */}
                        <StepButton {...buttonProps}>
                          <b style={{ color: color[index] }}>{label}</b>
                          {/* <div className={activeStep===index && classes.crd}>
                {activeStep===index && 
                   <Typography className={classes.instructions}>
                      {getStepContent(activeStep)}
                      </Typography>
                      }
                      </div> */}
                        </StepButton>
                      </Step>
                    </Tooltip>
                  );
                })}
              </Stepper>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
